// WARNING: DO NOT EDIT.  This file is automatically generated
// Written by aws-amplify-serverless-plugin/1.4.1 on 2025-02-12T09:32:48.872Z

const awsmobile = {
  aws_appsync_authenticationType: 'AWS_IAM',
  aws_appsync_graphqlEndpoint: 'https://6lrmwo6gkrd5vjlsogjdxg6ove.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_cloud_logic_custom: [
    {
      endpoint: 'https://69unb4nfj1.execute-api.eu-west-1.amazonaws.com/prod',
      name: 'ApiGatewayRestApi',
      region: 'eu-west-1'
    }
  ],
  aws_cognito_identity_pool_id: 'eu-west-1:f204cf4b-3693-47b2-8ec7-aecaafb2719b',
  aws_cognito_region: 'eu-west-1',
  aws_project_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_dAQMdlMhM',
  aws_user_pools_web_client_id: '565qa4iaaq8g8038uac3kj8bv1'
};

export default awsmobile;
