import React, {useState} from 'react';
import PartnerList from '../PartnerList';
import OfferList from '../OfferList';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import OfferPreview from '../Preview/OfferPreview';
import {useMutation} from '@apollo/client';
import {offerCategoryTranslator, gql_update_offer_adults_only, qql_get_offers_by_partner} from '../OfferList/helpers';
import {referralSourceList} from '../PartnerList/helpers';
import styles from './main.module.scss';
import moment from 'moment';

export default ({partnerData}) => {
  const [selectedOfferData, setSelectedOfferData] = useState({});
  const {
    brand,
    header,
    body,
    cta,
    referralUrl,
    discountCode,
    disclaimer,
    imageUrl,
    id,
    keywords,
    category,
    address,
    city,
    postalCode,
    status,
    dueDate,
    adultsOnly,
    futurePublicationDate,
    offerDuration,
    subscriptionId,
  } = selectedOfferData;

  const [currentPartnerId, setcurrentPartnerId] = useState('');
  const [hasSelectedOfferData, setHasSelectedOfferData] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState('');

  // only render partners with role 'offers' in the partner table on the offers side
  const formattedData = partnerData?.getPartners.partners.filter(partner => partner.service === 'offers').map((partner) => {
    return {...partner};
  });


  const [UpdateOfferAdultsOnly] = useMutation(
    gql_update_offer_adults_only,
    {
      refetchQueries: [{query: qql_get_offers_by_partner}],
    }
  );

  const handleChange = async (adultsOnly) => {
    try {
      await UpdateOfferAdultsOnly({
        variables: {id: id, adultsOnly: !adultsOnly},
      });
      setSelectedOfferData({...selectedOfferData, adultsOnly: !adultsOnly});
    }
    catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const formatStatus = (status) => {
    return status[0].toUpperCase() + status.slice(1).toLowerCase();
  };

  const referralTranslator = referral => {
    return referralSourceList[referral];
  };

  const renderPartnerData = (partner) => {
    return (
      <Container className={styles.col} fluid>
        <p>
          <span className={styles.labels}>Company Name:</span>{' '}
          {partner.company ? partner.company : ''}
        </p>
        <p>
          <span className={styles.labels}>Company ID:</span>{' '}
          {partner.companyID ? partner.companyID : ''}
        </p>
        <p>
          <span className={styles.labels}>Contact Person:</span>{' '}
          {partner.contactName ? partner.contactName : ''}
        </p>
        <p>
          <span className={styles.labels}>Contact Email:</span>{' '}
          {partner.contactEmail ? partner.contactEmail : ''}
        </p>
        <p>
          <span className={styles.labels}>Contact Phone:</span>{' '}
          {partner.contactPhone ? partner.contactPhone : ''}
        </p>
        <p>
          <span className={styles.labels}>Billing Address:</span>{' '}
          {partner.address ? partner.address : ''}
        </p>
        <p>
          <span className={styles.labels}>City:</span>{' '}
          {partner.city ? partner.city : ''}
        </p>
        <p>
          <span className={styles.labels}>Postal Code:</span>{' '}
          {partner.postcode ? partner.postcode : ''}
        </p>
        <p>
          <span className={styles.labels}>Company Size:</span>{' '}
          {partner.size ? partner.size : ''}
        </p>
        <p>
          <span className={styles.labels}>Referral:</span>{' '}
          {partner.referral ? referralTranslator(partner.referral) : ''}
        </p>
        <p>
          <span className={styles.labels}>Status:</span>{' '}
          {partner.status ? formatStatus(partner.status) : ''}
        </p>
      </Container>
    );
  };

  return (
    <div>
      <Container fluid>
        <Row className='justify-content-md-center'>
          {formattedData && (
            <Col sm='5'>
              <PartnerList
                formattedData={formattedData}
                onRowClick={(event, rowData) => {
                  const {id} = rowData;
                  setcurrentPartnerId(id);
                  setHasSelectedOfferData(false);
                  setSelectedPartner(rowData);
                }}
                role={'offers'}
              />
            </Col>
          )}
          <Col sm='7'>
            {currentPartnerId && (
              <OfferList
                currentPartnerId={currentPartnerId}
                setSelectedOfferData={setSelectedOfferData}
                selectedPartner={selectedPartner}
                onRowClick={(offerData) => {
                  setSelectedOfferData(offerData);
                  setHasSelectedOfferData(true);
                }}
              />
            )}
          </Col>
        </Row>
        <Row className={styles.bottomRow}>
          <Col>
            {currentPartnerId &&
              renderPartnerData(
                formattedData.find((partner) => partner.id === currentPartnerId)
              )}
          </Col>
          <Col>
            {hasSelectedOfferData && (
              <Container className={styles.col} fluid>
                <p>
                  <span className={styles.labels}>Offer ID: </span>
                  {id ? id : ''}
                </p>
                <div>
                  <label className={styles.labels}>{'Adults Only: '}
                    <input
                      type="checkbox"
                      // adultsOnly doesn't exist for older offers, so we need to give it a default value if null/undefined
                      checked={adultsOnly ? adultsOnly : false}
                      onChange={() => handleChange(adultsOnly)}
                    />
                  </label>
                </div>
                <p>
                  <span className={styles.labels}>Status: </span>
                  {status ? formatStatus(status) : ''}
                </p>
                <p>
                  <span className={styles.labels}>Brand: </span>
                  {brand ? brand : ''}
                </p>
                <p>
                  <span className={styles.labels}>Header: </span>
                  {header ? header : ''}
                </p>
                <p>
                  <span className={styles.labels}>Body: </span>
                  {body ? body : ''}
                </p>
                <p>
                  <span className={styles.labels}>Image URL: </span>
                  {imageUrl ? imageUrl : ''}
                </p>
                <p>
                  <span className={styles.labels}>Call to Action: </span>
                  {cta ? cta : ''}
                </p>
                <p>
                  <span className={styles.labels}>URL: </span>
                  {referralUrl ? referralUrl : ''}
                </p>
                <p>
                  <span className={styles.labels}>Discount Code: </span>
                  {discountCode ? discountCode : ''}
                </p>
                <p>
                  <span className={styles.labels}>Disclaimer: </span>
                  {disclaimer ? disclaimer : ''}
                </p>
                <p>
                  <span className={styles.labels}>Keywords: </span>
                  {keywords ? keywords : ''}
                </p>
                <p>
                  <span className={styles.labels}>Category: </span>
                  {category ? offerCategoryTranslator(category) : ''}
                </p>
                <p>
                  <span className={styles.labels}>Address: </span>
                  {address ? address : ''}
                </p>
                <p>
                  <span className={styles.labels}>City: </span>
                  {city ? city : ''}
                </p>
                <p>
                  <span className={styles.labels}>Postal Code: </span>
                  {postalCode ? postalCode : ''}
                </p>
                <p>
                  <span className={styles.labels}>Due Date: </span>
                  {dueDate ? moment(dueDate).format('D.M.YYYY') : ''}
                </p>
                <p>
                  <span className={styles.labels}>Future Publication Date: </span>
                  {futurePublicationDate ? moment(futurePublicationDate).format('D.M.YYYY') : ''}
                </p>
                <p>
                  <span className={styles.labels}>Offer Duration: </span>
                  {offerDuration ? `${offerDuration} months` : ''}
                </p>
                <p>
                  <span className={styles.labels}>Subscription ID: </span>
                  {subscriptionId ? `${subscriptionId}` : ''}
                </p>
              </Container>
            )}
          </Col>
          <Col sm='4' style={{maxWidth: '337px', minWidth: '337px'}}>
            {hasSelectedOfferData && <OfferPreview {...selectedOfferData} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
